import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import rawAxios from "../../../../axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import toolsHelper from "@/mixins/toolsHelper";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import neoSwitch from "@/components/toggle-switch";
import EmailReputationCard from "./email-reputation-card";

export default {
    name: "neo-email-reputation",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        "download-csv": JsonCSV,
        neoSwitch,
        EmailReputationCard,
    },
    mixins: [toolsHelper],
    props: {
        osintGraphDrawerOpen: {
            type: Boolean,
            default: false,
        },
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            social_platforms: null,
            loaderController: {
                reputation: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            email: "",
            userDetails: null,
            selectedItem: null,
            reputationUsers: [],
            showResult: false,
            selectVal: "",
            searchedClicked: false,
            tabName: "",
            userDetailsMonitoring: [],
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseEmails", "getAllMonitoringData", "getSelectedToolQuery", "getProduct", "getMonitoringDateFilter"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvData() {
            let data = [];
            if (this.isMonitoringTab) {
                data = this.userDetailsMonitoring;
            } else {
                data.push(this.userDetails);
            }
            return this.convertToCSV(data);
        },

        csvFields() {
            return ["email", "reputation", "suspicious", "details"];
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.userDetailsMonitoring && this.userDetailsMonitoring.length) return this.userDetailsMonitoring.every((result) => result.changes_viewed);
            else return false;
        },
    },
    watch: {
        // userDetails() {
        //   if(this.userDetails) {
        //     this.$emit('searchResult', {data:this.userDetails, source:'email-reputation', searchType:'tool'});
        //   }
        // }
        // $route(newRoute, prevRoute) {
        //     if (newRoute.params.toolName === "email-reputation-tool") {
        //         if (newRoute.query.q !== this.email) this.redirectToQuery();
        //     }
        // },
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.email) {
                this.redirectToQuery();
            }
        },

        // selectedItem() {
        //     this.showResult = false;
        // },
    },
    async mounted() {
        this.tabName = this.$route.name;
        this.case_id = this.$route.params.id;
        const dataRes = this.getToolsData(this.tabName, "email-reputation-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        const {data: social_platforms} = await rawAxios.get(`social_platforms`);
        this.social_platforms = social_platforms;
        this.getToolData();
    },
    beforeDestroy() {
        // this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "email-reputation-tool" });
        this.setToolsData(this.tabName, "email-reputation-tool");
    },
    methods: {
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseEmails", ""]),

        async getToolData() {
            this.reputationUsers = await this.getDropdownData("email");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Email",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "email_reputation", [...this.reputationUsers], data);
            allChecked = [...this.reputationUsers]?.length ? ([...this.reputationUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        setInput() {
            this.email = this.selectedItem.value.trim();
            // this.search();
        },

        setTheCustomInput(value) {
            this.email = value;
            this.selectedItem = {
                value: value.trim(),
            };
            // this.search();
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getProfileIcon(profile) {
            let icon = "";
            this.social_platforms.forEach((platform) => {
                if (platform.key === profile) {
                    icon = platform.icon_data;
                }
            });
            return icon;
        },

        async search() {
            this.showResult = false;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.email && emailRegex.test(this.email.trim())) {
                if (this.isMonitoringTab) await this.monitoringSearch();
                else await this.getEmailReputation();
                this.searchedClicked = true;
                await this.setSearchedUser(this.email);
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "email-reputation-tool"});
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        /**
         * Get monitoring search data result
         */
        async monitoringSearch() {
            const sources = ["email-reputation-tool"];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.showResult = true;
            this.userDetailsMonitoring = values;
        },

        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "email_reputation",
                val_type: this.selectedItem?.val_type || "email",
                visited: true,
            };
            const case_id = this.$route.params.id;
            let url = `research-methodology/${case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`reputationUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`reputationUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        async getEmailReputation() {
            this.startLoader("reputation");
            const url = "/tools/reputation/email";
            this.userDetails = [];
            const data = {
                email: this.email.trim(),
            };

            await axios
                .post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                })
                .then((response) => {
                    if (response && response.data.data.details) {
                        this.userDetails = response.data.data;
                    } else if (response && response.data.data.reason) {
                        this.$toast.error(response.data.data.reason);
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.resetLoader("reputation");
                    this.showResult = true;
                    this.$emit("searchResult", {data: this.userDetails, source: "email-reputation", searchType: "tool"});
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "email-reputation-tool"});
                })
                .catch((error) => {
                    this.resetLoader("reputation");
                    let message = "Please enter a valid input";
                    if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                    this.$toast.error(message);
                });
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];

                this.email = value;
                this.selectedItem = {
                    value: value.trim(),
                };
                this.selectVal = value;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        // handleAnalysis() {
        //     window.open(`${this.$route.fullPath}&analysis=email`, "_blank");
        // },
        handleAnalyse(source, value) {
            this.$emit("handleAnalyse", {source, value});
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            // this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal.trim(),
            };
            this.email = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
        async handleDone({target}, index) {
            this.userDetailsMonitoring[index].changes_viewed = target.checked;
            let doc_id = this.userDetailsMonitoring[index].monitoring_id;
            this.$forceUpdate();
            let sources = ["email-reputation-tool"];
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: target.checked,
            });
        },
        async handleMonitoring(index) {
            let sources = "email-reputation-tool";
            // this.userDetails.monitoring_status = !this.userDetails.monitoring_status;
            this.userDetailsMonitoring[index].monitoring_status = !this.userDetailsMonitoring[index].monitoring_status;
            let status = this.userDetailsMonitoring[index].monitoring_status ? "enabled" : "disabled";
            let card_id = this.userDetailsMonitoring[index].doc_id;
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source: sources,
                status,
            });
        },
        async handleMarkAllAsDone({target}) {
            let sources = ["email-reputation-tool"];
            this.userDetailsMonitoring.forEach((_, index) => {
                this.userDetailsMonitoring[index].changes_viewed = target.checked;
            });
            this.$forceUpdate();
            await rawAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources,
                mark_all_as_visited: target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
    },
};
