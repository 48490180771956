import {getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import neoSwitch from "@/components/toggle-switch";
import moment from "moment";
import NeoAnalyse from "@/components/analyse";
import {DateTime} from "luxon";

export default {
    name: "email-reputation-card",
    components: {
        neoSwitch,
        NeoAnalyse,
    },
    props: {
        userDetails: {
            type: Object,
            default: {},
        },
        isMonitoringTab: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        handleDone: {
            type: Function,
            default: () => 1,
        },
        handleMonitoring: {
            type: Function,
            default: () => 1,
        },
        getProfileIcon: {
            type: Function,
            default: () => 1,
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        getBackgroundStatus,
        getResultsID,
        getTime(item) {
            if (Date.parse(item)) {
                let date = new Date(item);
                return date ? moment(item).format("DD-MMMM-YYYY") : "";
            } else return item;
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
